.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #282c34;
}

.heading {
  color: white;
  font-weight: bold;
  padding: 16px;
  font-size: 30px;
}

.button-row {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button {
  margin: 10px;
  padding: 15px 25px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  background: blue;
  border: 2px solid #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
  transition: transform 0.2s, background 0.3s, box-shadow 0.3s;
}

.button:hover {
  transform: scale(1.1);
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.7);
}

.button:active {
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.7);
}
