

.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Takes the full viewport height */
    width: 100vw; /* Takes the full viewport width */
    position: fixed; /* Positions the spinner fixed over your content */
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent white background */
    z-index: 9999; /* Ensures the loader is above other content */
}

.loading-spinner {
    border: 5px solid #f3f3f3; /* Light grey border */
    border-top: 5px solid #3498db; /* Blue border */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-bottom: 10px; /* Adds some space between the spinner and the text */
    animation: spin 2s linear infinite;
}

.loading-text {
    font-size: 1.5rem;
    color: #333;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.dev-table-container {
    overflow: scroll;
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 10px 10px 10px;
    width: 100%;
}

.filters {
    flex:1;
    flex-direction: column;
    margin: 10px 10px 10px 10px;
}

.divider {
    text-align: center;
    font-size: 1.3em;
    color: #002842;
    padding: 10px 0;
    border-bottom: 2px solid #ddd;
    border-top: 2px solid #ddd;
    margin-bottom: 20px;
}
