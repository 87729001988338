/* General styles for the sidebar */
/*.sidebar {*/
/*    background-color: #f4f4f4;*/
/*    color: #333;*/
/*    padding: 20px;*/
/*    width: 250px;*/
/*    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);*/
/*    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;*/
/*}*/

/* Typography for headers within the sidebar */
.sidebar h2 {
    font-size: 1.5rem;
    font-weight: normal;
    margin-bottom: 20px; /* Increased to provide more space around headings */
}

/* Styles for individual filter items */
.sidebar .filter-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; /* Increased to provide more space between filter items */
}

/* Unified input styles for date pickers and select elements */
.sidebar .date-input,
.sidebar .select-input {
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px; /* Adjusted for consistent vertical rhythm */
    background-color: white; /* Ensures inputs stand out against the sidebar background */
}

/* Button styles for actions like 'Add Filter' and 'Query' */
.sidebar .button-style {
    width: calc(100% - 20px); /* Adjusted width to account for padding */
    padding: 10px 20px; /* Added horizontal padding for a wider clickable area */
    margin-bottom: 10px;
    font-weight: bold; /* Makes the button text more prominent */
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer; /* Changes the cursor to signify clickable buttons */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.sidebar .button-style:hover {
    background-color: #0056b3;
}

.sidebar .button-style:active {
    background-color: #003875;
}

/* Focus styles for inputs to replace the browser's default outline */
.sidebar .date-input:focus,
.sidebar .select-input:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

/* Hover effect for filter items to provide visual feedback */
.sidebar .filter-item:hover {
    background-color: #f9f9f9;
}

/* Additional container styles for layout within the sidebar */
.parent-flex-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start; /* Aligns children to the start of the container */
    align-items: stretch; /* Stretches children to fit the width of the container */
}

/* Style for the container holding date inputs */
.dateContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

/* Styles for elements within .dateContainer to ensure proper alignment */
.dateContainer > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Tooltip styles for consistency and clarity */
.tooltip {
    position: absolute;
    text-align: center;
    padding: 8px 12px;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #ddd;
    border-radius: 8px;
    pointer-events: none;
    white-space: nowrap;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    color: #333;
}

.legend-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 85%;
    padding-right: 2px;
    font-size: clamp(0.4vw, 0.6vw, 1vw)
}

.legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    justify-content: center;
    text-align: center;
    /*font-size: .5vw;*/
}

.legend-color {
    height: .5vw;
    width: .5vw;
    border-radius: 50%;
    margin-right: 10px;
    /*display: inline-block;*/
}

.legend-text {
    height: 100%;
    display: flex;
    align-items: center; /* Vertical alignment */
    justify-content: center;
    /* Additional styling for text if needed */
}

