/* In your CSS file */
.signup-form {
    max-width: 500px;
    margin: auto;
    padding: 2em;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.input-group {
    margin-bottom: 1em;
}

.input-field {
    width: 100%;
    padding: 0.75em;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1rem;
}

.submit-btn {
    width: 100%;
    padding: 1em;
    border: none;
    background-color: #002842;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.submit-btn:hover {
    background-color: #002842;
}

/* Overlay styles */
/* Overlay styles */
.overlay {
    position: fixed;
    inset: 0; /* Shorthand for top: 0; right: 0; bottom: 0; left: 0; */
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px); /* This blurs the background */
    z-index: 1000;
}

/* Sign-up form container */
.signup-form-container {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 1001;
    width: auto;
    max-width: 500px; /* Adjust the width as needed */
}

/* Close button styles */
.close-btn {
    position: absolute;
    top: 2px;
    right: 2px;
    border: none;
    background: none;
    font-size: 20px;
    cursor: pointer;
}
