.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns with equal width */
    /*gap: 20px; !* Space between grid items *!*/
    height: 70%;
    /* Additional styles if needed (e.g., padding, alignment) */
}

.row-count-display {
    /*margin-top: 10px;*/
    text-align: center;
    padding: 10px 20px;
    font-size: 0.9em;
    color: #555;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ddd;
    z-index: 5;

}

.table-header-display {
    /*margin-top: 10px;*/
    top: 0;
    text-align: center;
    padding: 10px 20px;
    font-size: 1em;
    font-weight: bold;
    color: #002842;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ddd;
    z-index: 5;
    position: sticky;
}