.dropdown-menu {
    position: absolute;
    top: 23%;
    left: 0;
    float: left;
    min-width: 10rem;
    margin-left: 10px;
    margin-top: 5px;
    font-size: 1rem;
    text-align: left;
    list-style: none;
    background-color: #fff;
    border-radius: 0.25rem;
    border: solid 1px #002842;
    font-weight: bold;
    color: #002842;
}
