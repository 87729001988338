.bar-chart-section {
    width: 100%;
    height: 47vh;
    max-height: 47vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    border-radius: 10px;
}

.left-chart-container {
    width: 65%;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
}

.right-chart-container {
    display: flex;
    width: 33%;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
}

.period-selector {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    padding-top: 30px;
    white-space: nowrap;
}

.chart-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /*align-items: center;*/
    /*width: 100%;*/
}

.chart-title-text {
    flex-grow: 1;
    text-align: center;
}

.chart-title-icon {
    flex-shrink: 0;
    padding-right: 10px;
}

.asset-title {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.asset-title-text {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.mass-title {
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-top: 30px;
}
