.data-table-container {
  font-family: 'Open Sans', sans-serif;
  /*max-height: 85vh;*/
  height: 100%;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
  background-color: #fdfdfd;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
}

th, td {
  text-align: left;
  padding: 8px 15px;
  cursor: pointer;
  white-space: nowrap;
}

thead th {
  background-color: #f5f5f7;
  color: #333;
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 10;
}

tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

tbody tr:nth-child(even) {
  background-color: #f9f9fb;
}

.pagination button {
  background-color: #f0f0f7;
  color: #333;
  padding: 8px 16px;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.pagination button:hover, .pagination button:focus {
  background-color: #e0e0eb;
  outline: none;
}

.pagination .active {
  box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
}

.data-table-container::-webkit-scrollbar {
  width: 16px; /* Width of the scrollbar */
  background-color: #F5F5F5; /* Background color */
}

/* Handle */
.data-table-container::-webkit-scrollbar-thumb {
  background-color: #CCCCCC; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Roundness of the scrollbar thumb */
  border: 3px solid #F5F5F5; /* Creates padding around the scrollbar thumb */
}

/* Handle on hover */
.data-table-container::-webkit-scrollbar-thumb:hover {
  background-color: #B0B0B0;
}

/* Handle active */
.data-table-container::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

