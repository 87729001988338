.tooltip {
    position: absolute;
    text-align: center;
    padding: 8px;
    font: 12px sans-serif;
    background: lightsteelblue;
    border: 0px;
    border-radius: 8px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s;

}

.no-tick-line line {
    display: none;
}


.parent-flex-container-bar{
    /*padding: 10px;*/
    overflow: auto;
    display: block;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center; /* Aligns children to the start of the container */
    /*box-sizing: border-box;*/
}

.d3-component{
    /*overflow: visible;*/
    align-items: baseline;
    display: flex;
    flex-grow: 1;
}
.hovered-bar {
    /*stroke: red;*/
    /*stroke-width: 20px;*/
}
