/* LoginPage.css */

.login-page {
    display: flex;
    width: 100vw;
    height: 100vh;
}

.login-left-side {
    flex: 1;
    background-size: cover;
    background-position: center; /* You can change this to 'top', 'bottom', 'left', 'right', or even specific values like '50% 20%' */
}

.login-right-side {
    flex: 1;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Align items to the start */
    /*margin-top: -5vh;*/
}

.logo {
    /*max-width: 300px;*/
    width: 50%;
    max-height: 33vh;
    justify-self: flex-start;
    /*margin-bottom: 20px;*/
}
.logo-text {
    font-size: 2em;
    font-weight: bold;
    color: #002842;
    margin-bottom: 2%;

}

.login-card {
    background-color: white;
    padding: 4%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: 60%;
    margin-top: 5%;
}

.login-form {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.login-form input {
    width: 45%;
}

/*input[type="text"] {*/
/* cursor: inherit;*/
/*    background-color: black !important;*/
/*}*/

/*.login-form input[type="password"] {*/
/*    padding: 10px;*/
/*    margin-bottom: 10px;*/
/*    border: 1px solid #ddd;*/
/*    border-radius: 4px;*/
/*}*/

.login-form button {
    padding: 1%;
    background-color: #002842;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.login-form button:hover {
    background-color: #004494;
}

.login-form a {
    color: #0056b3;
    text-decoration: none;
    display: block;
    margin-top: 1.5%;
    text-align: center;
}

/* Image placeholders */
.image-placeholder {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 2em;
    background-color: #333; /* Placeholder background color */
}


.input-icon-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 49%;

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input.input-with-icon {
    border: none;
    padding: 1.5%;
    flex-grow: 1;
    background-color: white !important;
}

input.input-with-icon:focus {
    outline: none;
}

.icon {
    /*min-width: 50px;*/
    text-align: center;
    color: #f87f00;
    padding: 15px 0 15px 5px;
    /*font-size: 3em !important;*/
}

.error-message {
    color: red;
    margin-top: 1.5%;
    margin-bottom: -1.5%;
    width: 60%;
    display: flex;
    justify-content: center;
}

.non-clickable {
    pointer-events: none;
    opacity: 0.6;
    cursor: not-allowed;
}

.different-user-button {
    background-color: white !important;
    color: #0070E0 !important;
    font-size: 1.1em;
    text-decoration: none;
    border: none;
    cursor: pointer;
    outline: none;
}

.different-user-button:hover {
    text-decoration: underline;
}

/**/


/* Add more styles for hover effects, active states, focus states, etc. */
