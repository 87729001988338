.parent-flex-container-silo{
    /*padding: 10px;*/
    overflow: auto;
    display: block;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center; /* Aligns children to the start of the container */
    /*box-sizing: border-box;*/
}
