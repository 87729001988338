/* General styles for the sidebar */
.sidebar {
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: height 0.3s ease; /* Smooth transition for sidebar width */
    background-color: #f4f4f4;
    color: #333;
    padding-top: 10px;
    width: 100%;
    height: 62px;
    padding-left: 1vw;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    position: fixed;
    z-index: 999;
    top: 79px
    /*top: 100%*/
}

.clear-all-icon {
    color: black;
    cursor: pointer;
    font-size: 30px !important;
}

.clear-all-icon:hover {
    color: blue;
}

.sidebar.collapsed{
    /*padding-right: 30px;*/
    background-color: #dfdddd;
}

.toggle-button {
    height: 25px;
    width: 25px;
    position: absolute;
    transition: right 0.3s ease; /* Smooth transition for button position */
}

.toggle-button .MuiSvgIcon-root {
    /* Increase the font-size to scale up the icon */
    font-size: 20rem; /* Or any size */
    color: #002842;
    /*border-radius: 50%;*/
    height: 25px;
    width: 25px;
}

/* When the sidebar is expanded */
.sidebar.expanded .toggle-button {
    top: 20px;
    left: 97%;
    transform: translate(0, 25%);
    transition: right 0.3s ease;

}

/* When the sidebar is collapsed */
.sidebar.collapsed .toggle-button {
    top: 0px;
    left: 97%;
    /*transform: translate(0, -50%); !* Centers the button vertically *!*/
    /*transition: right 0.3s ease; !* Smooth transition for button position *!*/
    /*margin-right: 25px;*/
    display: flex;
}

.sidebar.collapsed {
    width: 100%; /* Width of the collapsed sidebar */
    height: 1px;
    padding: 10px;
}

.sidebar.collapsed >div {
    display: none;
    padding: 0;
}


/* Typography for headers within the sidebar */
.sidebar h2 {
    font-size: 1.5rem;
    font-weight: normal;
    margin-bottom: 20px; /* Increased to provide more space around headings */
}


.date-container {
    display: flex;
    flex-direction: row;
}

/* Styles for individual filter items */
.sidebar .filter-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; /* Increased to provide more space between filter items */



}

/* Unified input styles for date pickers and select elements */
.sidebar .date-input {
    font-family: "acumin-pro", sans-serif;
    /*font-weight: 400;*/
    font-style: normal;
}
.sidebar .select-input {
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-bottom: 10px; /* Adjusted for consistent vertical rhythm */
    background-color: white; /* Ensures inputs stand out against the sidebar background */
}

/* Button styles for actions like 'Add Filter' and 'Query' */
.sidebar .button-style {
    width: calc(100% - 20px); /* Adjusted width to account for padding */
    padding: 10px 20px; /* Added horizontal padding for a wider clickable area */
    margin-bottom: 10px;
    font-weight: bold; /* Makes the button text more prominent */
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer; /* Changes the cursor to signify clickable buttons */
    /*transition: background-color 0.3s ease; !* Smooth transition for hover effect *!*/
}

.sidebar .button-style:hover {
    background-color: #0056b3;
}

.sidebar .button-style:active {
    background-color: #003875;
}

/* Focus styles for inputs to replace the browser's default outline */
.sidebar .date-input:focus,
.sidebar .select-input:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

/* Hover effect for filter items to provide visual feedback */
.sidebar .filter-item:hover {
    background-color: #f9f9f9;
}

/* Additional container styles for layout within the sidebar */
.parent-flex-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center; /* Aligns children to the start of the container */
    align-items: center; /* Stretches children to fit the width of the container */
    overflow: auto;
}

/* Style for the container holding date inputs */
.dateContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

/* Styles for elements within .dateContainer to ensure proper alignment */
.dateContainer > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Tooltip styles for consistency and clarity */
.tooltip {
    position: absolute;
    text-align: center;
    padding: 8px 12px;
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #ddd;
    border-radius: 8px;
    pointer-events: none;
    white-space: nowrap;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    color: #333;
}


.date-label-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
    z-index: 1;
}

.date-display-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fff !important;
    border-radius: 5px;
    border: 1px solid black;
    padding: 7px;
    margin-right: 5px;
    font-weight: bold;
}

.filter-item {
    background-color: #ffffff; /* Light background for each filter item */
    border: 1px solid #e1e4e8; /* Subtle border to define the edges */
    border-radius: 6px; /* Rounded corners for a modern look */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Soft shadow for depth */
    display: flex;
    align-items: center;
    gap: 10px; /* Space between child elements */
    padding: 10px; /* Internal spacing */
    margin-bottom: 15px; /* Space between each filter item */
    transition: box-shadow 0.2s ease-in-out; /* Smooth transition for hover effect */
}

.filter-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Enhanced shadow on hover for interaction feedback */
}

/* Styling for select inputs */
.select-input {
    flex-grow: 1;
    /*margin-right: 10px; !* Ensures a gap on the right side *!*/
}

/* Styling for text fields */
.text-field {
    /*flex-grow: 2; !* Allows text field to take more space *!*/
    /*margin-right: 10px; !* Ensures a gap on the right side *!*/
}

/* Styling for the delete icon button */
.delete-button {
    color: red; /* Sets the default color for the icon */
    padding: 8px; /* Ensures the button is easy to click */
    margin-left: auto; /* Pushes the button to the end of the flex item */
}

.delete-button:hover {
    background-color: rgba(255, 0, 0, 0.1); /* Adds a subtle hover effect */
}

