.confirmDialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 99;
}

.confirmDialog {
    background: white;
    padding: 1vw;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /*width: 20vw;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 33vh;

}

.confirmDialog-message {
    margin-bottom: 2vh;
    text-align: center;
}

.confirmDialog-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.button-cancel, .button-confirm {
    padding: .5vw 1vw;
    border: none;
    color: white;
    cursor: pointer;
    font-weight: bold;
}

.button-cancel {
    background-color: #f87f00; /* Red */
    border-radius: 5px;
}

.button-confirm {
    background-color: #002842;
    border-radius: 5px;

; /* Green */
}
