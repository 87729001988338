.login-page-first {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.login-page-first .div {
  background-color: #ffffff;
  height: 1080px;
  overflow: hidden;
  position: relative;
  width: 1920px;
}

.login-page-first .overlap {
  background-image: url(../../../public/img/image.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 1080px;
  left: -1px;
  position: absolute;
  top: 0;
  width: 961px;
}

.login-page-first .image-placeholder {
  background-image: url(../../../public/img/path-12.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 84px;
  left: 420px;
  position: relative;
  top: 498px;
  width: 121px;
}

.login-page-first .overlap-group {
  height: 1080px;
  left: 1130px;
  position: absolute;
  top: 214px;
  width: 1210px;
}

.login-page-first .overlap-2 {
  height: 1080px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1210px;
}

.login-page-first .text-wrapper {
  color: #262626;
  font-family: "Governor-Regular", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 168px;
  letter-spacing: 0;
  line-height: 64px;
  position: absolute;
  text-align: center;
  top: 229px;
  white-space: nowrap;
}

.login-page-first .imining-logo-blue {
  background-image: url(../../../public/img/web-imining-logo-blue-large.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 203px;
  left: 117px;
  position: absolute;
  top: 0;
  width: 389px;
}

.login-page-first .group {
  height: 923px;
  left: 441px;
  opacity: 0.14;
  position: absolute;
  top: 157px;
  width: 769px;
}

.login-page-first .overlap-3 {
  height: 923px;
  position: relative;
}

.login-page-first .group-2 {
  background-image: url(../../../public/img/path-3046.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 85px;
  left: 399px;
  position: absolute;
  top: 838px;
  width: 370px;
}

.login-page-first .group-3 {
  background-image: url(../../../public/img/path-3047.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 920px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 769px;
}

.login-page-first .overlap-wrapper {
  height: 847px;
  left: 164px;
  position: absolute;
  top: 22px;
  width: 605px;
}

.login-page-first .overlap-group-2 {
  height: 847px;
  position: relative;
}

.login-page-first .overlap-group-wrapper {
  height: 847px;
  left: 0;
  position: absolute;
  top: 0;
  width: 605px;
}

.login-page-first .path {
  height: 389px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 394px;
  width: 266px;
}

.login-page-first .img {
  height: 267px;
  left: 88px;
  object-fit: cover;
  position: absolute;
  top: 580px;
  width: 458px;
}

.login-page-first .path-2 {
  height: 512px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 65px;
  width: 446px;
}

.login-page-first .path-3 {
  height: 271px;
  left: 137px;
  object-fit: cover;
  position: absolute;
  top: 521px;
  width: 467px;
}

.login-page-first .path-4 {
  height: 265px;
  left: 140px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 259px;
}

.login-page-first .path-5 {
  height: 188px;
  left: 51px;
  object-fit: cover;
  position: absolute;
  top: 153px;
  width: 205px;
}

.login-page-first .path-6 {
  height: 144px;
  left: 117px;
  object-fit: cover;
  position: absolute;
  top: 585px;
  width: 138px;
}

.login-page-first .path-7 {
  height: 34px;
  left: 49px;
  object-fit: cover;
  position: absolute;
  top: 368px;
  width: 127px;
}

.login-page-first .path-8 {
  height: 253px;
  left: 315px;
  object-fit: cover;
  position: absolute;
  top: 2px;
  width: 42px;
}

.login-page-first .path-9 {
  height: 544px;
  left: 112px;
  object-fit: cover;
  position: absolute;
  top: 179px;
  width: 493px;
}

.login-page-first .path-10 {
  height: 474px;
  left: 148px;
  object-fit: cover;
  position: absolute;
  top: 213px;
  width: 456px;
}

.login-page-first .path-11 {
  height: 20px;
  left: 582px;
  object-fit: cover;
  position: absolute;
  top: 584px;
  width: 15px;
}

.login-page-first .path-12 {
  height: 2px;
  left: 437px;
  object-fit: cover;
  position: absolute;
  top: 674px;
  width: 4px;
}

.login-page-first .path-13 {
  height: 2px;
  left: 431px;
  object-fit: cover;
  position: absolute;
  top: 676px;
  width: 4px;
}

.login-page-first .path-14 {
  height: 2px;
  left: 425px;
  object-fit: cover;
  position: absolute;
  top: 677px;
  width: 4px;
}

.login-page-first .path-15 {
  height: 2px;
  left: 419px;
  object-fit: cover;
  position: absolute;
  top: 678px;
  width: 4px;
}

.login-page-first .path-16 {
  height: 1px;
  left: 413px;
  object-fit: cover;
  position: absolute;
  top: 679px;
  width: 4px;
}

.login-page-first .path-17 {
  height: 1px;
  left: 407px;
  object-fit: cover;
  position: absolute;
  top: 680px;
  width: 4px;
}

.login-page-first .path-18 {
  height: 1px;
  left: 401px;
  object-fit: cover;
  position: absolute;
  top: 680px;
  width: 4px;
}

.login-page-first .path-19 {
  height: 1px;
  left: 394px;
  object-fit: cover;
  position: absolute;
  top: 681px;
  width: 4px;
}

.login-page-first .path-20 {
  height: 1px;
  left: 388px;
  object-fit: cover;
  position: absolute;
  top: 681px;
  width: 4px;
}

.login-page-first .path-21 {
  height: 1px;
  left: 382px;
  object-fit: cover;
  position: absolute;
  top: 681px;
  width: 4px;
}

.login-page-first .path-22 {
  height: 1px;
  left: 376px;
  object-fit: cover;
  position: absolute;
  top: 681px;
  width: 4px;
}

.login-page-first .path-23 {
  height: 1px;
  left: 370px;
  object-fit: cover;
  position: absolute;
  top: 681px;
  width: 4px;
}

.login-page-first .path-24 {
  height: 1px;
  left: 363px;
  object-fit: cover;
  position: absolute;
  top: 680px;
  width: 4px;
}

.login-page-first .path-25 {
  height: 1px;
  left: 357px;
  object-fit: cover;
  position: absolute;
  top: 680px;
  width: 4px;
}

.login-page-first .path-26 {
  height: 1px;
  left: 351px;
  object-fit: cover;
  position: absolute;
  top: 679px;
  width: 4px;
}

.login-page-first .path-27 {
  height: 2px;
  left: 345px;
  object-fit: cover;
  position: absolute;
  top: 678px;
  width: 4px;
}

.login-page-first .path-28 {
  height: 2px;
  left: 339px;
  object-fit: cover;
  position: absolute;
  top: 677px;
  width: 4px;
}

.login-page-first .path-29 {
  height: 2px;
  left: 333px;
  object-fit: cover;
  position: absolute;
  top: 675px;
  width: 4px;
}

.login-page-first .path-30 {
  height: 2px;
  left: 327px;
  object-fit: cover;
  position: absolute;
  top: 674px;
  width: 4px;
}

.login-page-first .path-31 {
  height: 2px;
  left: 321px;
  object-fit: cover;
  position: absolute;
  top: 672px;
  width: 4px;
}

.login-page-first .path-32 {
  height: 2px;
  left: 315px;
  object-fit: cover;
  position: absolute;
  top: 670px;
  width: 4px;
}

.login-page-first .path-33 {
  height: 2px;
  left: 309px;
  object-fit: cover;
  position: absolute;
  top: 668px;
  width: 4px;
}

.login-page-first .path-34 {
  height: 2px;
  left: 303px;
  object-fit: cover;
  position: absolute;
  top: 666px;
  width: 4px;
}

.login-page-first .path-35 {
  height: 2px;
  left: 297px;
  object-fit: cover;
  position: absolute;
  top: 664px;
  width: 4px;
}

.login-page-first .path-36 {
  height: 2px;
  left: 291px;
  object-fit: cover;
  position: absolute;
  top: 662px;
  width: 4px;
}

.login-page-first .path-37 {
  height: 2px;
  left: 286px;
  object-fit: cover;
  position: absolute;
  top: 659px;
  width: 4px;
}

.login-page-first .path-38 {
  height: 3px;
  left: 280px;
  object-fit: cover;
  position: absolute;
  top: 656px;
  width: 4px;
}

.login-page-first .path-39 {
  height: 3px;
  left: 274px;
  object-fit: cover;
  position: absolute;
  top: 653px;
  width: 4px;
}

.login-page-first .path-40 {
  height: 3px;
  left: 269px;
  object-fit: cover;
  position: absolute;
  top: 650px;
  width: 4px;
}

.login-page-first .path-41 {
  height: 3px;
  left: 264px;
  object-fit: cover;
  position: absolute;
  top: 647px;
  width: 4px;
}

.login-page-first .path-42 {
  height: 3px;
  left: 258px;
  object-fit: cover;
  position: absolute;
  top: 644px;
  width: 4px;
}

.login-page-first .path-43 {
  height: 3px;
  left: 253px;
  object-fit: cover;
  position: absolute;
  top: 640px;
  width: 4px;
}

.login-page-first .path-44 {
  height: 3px;
  left: 248px;
  object-fit: cover;
  position: absolute;
  top: 637px;
  width: 4px;
}

.login-page-first .path-45 {
  height: 3px;
  left: 243px;
  object-fit: cover;
  position: absolute;
  top: 633px;
  width: 4px;
}

.login-page-first .path-46 {
  height: 3px;
  left: 238px;
  object-fit: cover;
  position: absolute;
  top: 629px;
  width: 4px;
}

.login-page-first .path-47 {
  height: 3px;
  left: 233px;
  object-fit: cover;
  position: absolute;
  top: 625px;
  width: 3px;
}

.login-page-first .path-48 {
  height: 3px;
  left: 229px;
  object-fit: cover;
  position: absolute;
  top: 621px;
  width: 3px;
}

.login-page-first .path-49 {
  height: 3px;
  left: 224px;
  object-fit: cover;
  position: absolute;
  top: 617px;
  width: 3px;
}

.login-page-first .path-50 {
  height: 3px;
  left: 220px;
  object-fit: cover;
  position: absolute;
  top: 612px;
  width: 3px;
}

.login-page-first .path-51 {
  height: 3px;
  left: 215px;
  object-fit: cover;
  position: absolute;
  top: 608px;
  width: 3px;
}

.login-page-first .path-52 {
  height: 3px;
  left: 211px;
  object-fit: cover;
  position: absolute;
  top: 603px;
  width: 3px;
}

.login-page-first .path-53 {
  height: 3px;
  left: 207px;
  object-fit: cover;
  position: absolute;
  top: 599px;
  width: 3px;
}

.login-page-first .path-54 {
  height: 4px;
  left: 203px;
  object-fit: cover;
  position: absolute;
  top: 594px;
  width: 3px;
}

.login-page-first .path-55 {
  height: 4px;
  left: 199px;
  object-fit: cover;
  position: absolute;
  top: 589px;
  width: 3px;
}

.login-page-first .path-56 {
  height: 4px;
  left: 196px;
  object-fit: cover;
  position: absolute;
  top: 584px;
  width: 3px;
}

.login-page-first .path-57 {
  height: 4px;
  left: 192px;
  object-fit: cover;
  position: absolute;
  top: 579px;
  width: 3px;
}

.login-page-first .path-58 {
  height: 4px;
  left: 189px;
  object-fit: cover;
  position: absolute;
  top: 574px;
  width: 3px;
}

.login-page-first .path-59 {
  height: 4px;
  left: 186px;
  object-fit: cover;
  position: absolute;
  top: 568px;
  width: 3px;
}

.login-page-first .path-60 {
  height: 4px;
  left: 183px;
  object-fit: cover;
  position: absolute;
  top: 563px;
  width: 3px;
}

.login-page-first .path-61 {
  height: 4px;
  left: 180px;
  object-fit: cover;
  position: absolute;
  top: 557px;
  width: 3px;
}

.login-page-first .path-62 {
  height: 4px;
  left: 177px;
  object-fit: cover;
  position: absolute;
  top: 552px;
  width: 3px;
}

.login-page-first .path-63 {
  height: 4px;
  left: 174px;
  object-fit: cover;
  position: absolute;
  top: 546px;
  width: 2px;
}

.login-page-first .path-64 {
  height: 4px;
  left: 172px;
  object-fit: cover;
  position: absolute;
  top: 541px;
  width: 2px;
}

.login-page-first .path-65 {
  height: 4px;
  left: 169px;
  object-fit: cover;
  position: absolute;
  top: 535px;
  width: 2px;
}

.login-page-first .path-66 {
  height: 4px;
  left: 167px;
  object-fit: cover;
  position: absolute;
  top: 529px;
  width: 2px;
}

.login-page-first .path-67 {
  height: 4px;
  left: 165px;
  object-fit: cover;
  position: absolute;
  top: 523px;
  width: 2px;
}

.login-page-first .path-68 {
  height: 4px;
  left: 163px;
  object-fit: cover;
  position: absolute;
  top: 517px;
  width: 2px;
}

.login-page-first .path-69 {
  height: 4px;
  left: 161px;
  object-fit: cover;
  position: absolute;
  top: 511px;
  width: 2px;
}

.login-page-first .path-70 {
  height: 4px;
  left: 160px;
  object-fit: cover;
  position: absolute;
  top: 505px;
  width: 2px;
}

.login-page-first .path-71 {
  height: 4px;
  left: 158px;
  object-fit: cover;
  position: absolute;
  top: 499px;
  width: 2px;
}

.login-page-first .path-72 {
  height: 4px;
  left: 157px;
  object-fit: cover;
  position: absolute;
  top: 493px;
  width: 2px;
}

.login-page-first .path-73 {
  height: 4px;
  left: 156px;
  object-fit: cover;
  position: absolute;
  top: 487px;
  width: 2px;
}

.login-page-first .path-74 {
  height: 4px;
  left: 155px;
  object-fit: cover;
  position: absolute;
  top: 481px;
  width: 2px;
}

.login-page-first .path-75 {
  height: 4px;
  left: 154px;
  object-fit: cover;
  position: absolute;
  top: 475px;
  width: 1px;
}

.login-page-first .path-76 {
  height: 4px;
  left: 154px;
  object-fit: cover;
  position: absolute;
  top: 469px;
  width: 1px;
}

.login-page-first .path-77 {
  height: 4px;
  left: 153px;
  object-fit: cover;
  position: absolute;
  top: 462px;
  width: 1px;
}

.login-page-first .path-78 {
  height: 4px;
  left: 153px;
  object-fit: cover;
  position: absolute;
  top: 456px;
  width: 1px;
}

.login-page-first .path-79 {
  height: 4px;
  left: 153px;
  object-fit: cover;
  position: absolute;
  top: 450px;
  width: 1px;
}

.login-page-first .path-80 {
  height: 4px;
  left: 153px;
  object-fit: cover;
  position: absolute;
  top: 444px;
  width: 1px;
}

.login-page-first .path-81 {
  height: 4px;
  left: 153px;
  object-fit: cover;
  position: absolute;
  top: 437px;
  width: 1px;
}

.login-page-first .path-82 {
  height: 4px;
  left: 154px;
  object-fit: cover;
  position: absolute;
  top: 431px;
  width: 1px;
}

.login-page-first .path-83 {
  height: 4px;
  left: 154px;
  object-fit: cover;
  position: absolute;
  top: 425px;
  width: 1px;
}

.login-page-first .path-84 {
  height: 4px;
  left: 155px;
  object-fit: cover;
  position: absolute;
  top: 419px;
  width: 1px;
}

.login-page-first .path-85 {
  height: 4px;
  left: 156px;
  object-fit: cover;
  position: absolute;
  top: 413px;
  width: 2px;
}

.login-page-first .path-86 {
  height: 4px;
  left: 157px;
  object-fit: cover;
  position: absolute;
  top: 406px;
  width: 2px;
}

.login-page-first .path-87 {
  height: 4px;
  left: 158px;
  object-fit: cover;
  position: absolute;
  top: 400px;
  width: 2px;
}

.login-page-first .path-88 {
  height: 2px;
  left: 303px;
  object-fit: cover;
  position: absolute;
  top: 232px;
  width: 4px;
}

.login-page-first .path-89 {
  height: 2px;
  left: 310px;
  object-fit: cover;
  position: absolute;
  top: 230px;
  width: 4px;
}

.login-page-first .path-90 {
  height: 2px;
  left: 316px;
  object-fit: cover;
  position: absolute;
  top: 228px;
  width: 4px;
}

.login-page-first .path-91 {
  height: 2px;
  left: 323px;
  object-fit: cover;
  position: absolute;
  top: 226px;
  width: 4px;
}

.login-page-first .path-92 {
  height: 2px;
  left: 330px;
  object-fit: cover;
  position: absolute;
  top: 225px;
  width: 4px;
}

.login-page-first .path-93 {
  height: 2px;
  left: 336px;
  object-fit: cover;
  position: absolute;
  top: 223px;
  width: 4px;
}

.login-page-first .path-94 {
  height: 2px;
  left: 343px;
  object-fit: cover;
  position: absolute;
  top: 222px;
  width: 4px;
}

.login-page-first .path-95 {
  height: 2px;
  left: 350px;
  object-fit: cover;
  position: absolute;
  top: 221px;
  width: 4px;
}

.login-page-first .path-96 {
  height: 1px;
  left: 356px;
  object-fit: cover;
  position: absolute;
  top: 220px;
  width: 4px;
}

.login-page-first .path-97 {
  height: 1px;
  left: 363px;
  object-fit: cover;
  position: absolute;
  top: 220px;
  width: 4px;
}

.login-page-first .path-98 {
  height: 1px;
  left: 370px;
  object-fit: cover;
  position: absolute;
  top: 219px;
  width: 4px;
}

.login-page-first .path-99 {
  height: 1px;
  left: 377px;
  object-fit: cover;
  position: absolute;
  top: 219px;
  width: 4px;
}

.login-page-first .path-100 {
  height: 1px;
  left: 383px;
  object-fit: cover;
  position: absolute;
  top: 219px;
  width: 4px;
}

.login-page-first .path-101 {
  height: 1px;
  left: 390px;
  object-fit: cover;
  position: absolute;
  top: 219px;
  width: 4px;
}

.login-page-first .path-102 {
  height: 1px;
  left: 397px;
  object-fit: cover;
  position: absolute;
  top: 219px;
  width: 4px;
}

.login-page-first .path-103 {
  height: 1px;
  left: 404px;
  object-fit: cover;
  position: absolute;
  top: 220px;
  width: 4px;
}

.login-page-first .path-104 {
  height: 1px;
  left: 411px;
  object-fit: cover;
  position: absolute;
  top: 220px;
  width: 4px;
}

.login-page-first .path-105 {
  height: 2px;
  left: 417px;
  object-fit: cover;
  position: absolute;
  top: 221px;
  width: 4px;
}

.login-page-first .path-106 {
  height: 2px;
  left: 424px;
  object-fit: cover;
  position: absolute;
  top: 222px;
  width: 4px;
}

.login-page-first .path-107 {
  height: 2px;
  left: 431px;
  object-fit: cover;
  position: absolute;
  top: 224px;
  width: 4px;
}

.login-page-first .path-108 {
  height: 2px;
  left: 438px;
  object-fit: cover;
  position: absolute;
  top: 222px;
  width: 4px;
}

.login-page-first .path-109 {
  height: 2px;
  left: 445px;
  object-fit: cover;
  position: absolute;
  top: 224px;
  width: 4px;
}

.login-page-first .path-110 {
  height: 2px;
  left: 451px;
  object-fit: cover;
  position: absolute;
  top: 226px;
  width: 4px;
}

.login-page-first .path-111 {
  height: 2px;
  left: 458px;
  object-fit: cover;
  position: absolute;
  top: 228px;
  width: 4px;
}

.login-page-first .path-112 {
  height: 2px;
  left: 464px;
  object-fit: cover;
  position: absolute;
  top: 230px;
  width: 4px;
}

.login-page-first .path-113 {
  height: 2px;
  left: 470px;
  object-fit: cover;
  position: absolute;
  top: 233px;
  width: 4px;
}

.login-page-first .path-114 {
  height: 2px;
  left: 477px;
  object-fit: cover;
  position: absolute;
  top: 235px;
  width: 4px;
}

.login-page-first .path-115 {
  height: 3px;
  left: 483px;
  object-fit: cover;
  position: absolute;
  top: 238px;
  width: 4px;
}

.login-page-first .path-116 {
  height: 3px;
  left: 489px;
  object-fit: cover;
  position: absolute;
  top: 241px;
  width: 4px;
}

.login-page-first .path-117 {
  height: 3px;
  left: 495px;
  object-fit: cover;
  position: absolute;
  top: 244px;
  width: 4px;
}

.login-page-first .path-118 {
  height: 3px;
  left: 501px;
  object-fit: cover;
  position: absolute;
  top: 247px;
  width: 4px;
}

.login-page-first .path-119 {
  height: 3px;
  left: 507px;
  object-fit: cover;
  position: absolute;
  top: 251px;
  width: 4px;
}

.login-page-first .path-120 {
  height: 3px;
  left: 513px;
  object-fit: cover;
  position: absolute;
  top: 255px;
  width: 4px;
}

.login-page-first .path-121 {
  height: 3px;
  left: 518px;
  object-fit: cover;
  position: absolute;
  top: 258px;
  width: 4px;
}

.login-page-first .path-122 {
  height: 3px;
  left: 524px;
  object-fit: cover;
  position: absolute;
  top: 262px;
  width: 4px;
}

.login-page-first .path-123 {
  height: 3px;
  left: 529px;
  object-fit: cover;
  position: absolute;
  top: 266px;
  width: 4px;
}

.login-page-first .path-124 {
  height: 3px;
  left: 534px;
  object-fit: cover;
  position: absolute;
  top: 271px;
  width: 4px;
}

.login-page-first .path-125 {
  height: 3px;
  left: 540px;
  object-fit: cover;
  position: absolute;
  top: 275px;
  width: 3px;
}

.login-page-first .path-126 {
  height: 3px;
  left: 545px;
  object-fit: cover;
  position: absolute;
  top: 280px;
  width: 3px;
}

.login-page-first .path-127 {
  height: 3px;
  left: 549px;
  object-fit: cover;
  position: absolute;
  top: 285px;
  width: 3px;
}

.login-page-first .path-128 {
  height: 3px;
  left: 554px;
  object-fit: cover;
  position: absolute;
  top: 289px;
  width: 3px;
}

.login-page-first .path-129 {
  height: 3px;
  left: 559px;
  object-fit: cover;
  position: absolute;
  top: 294px;
  width: 3px;
}

.login-page-first .path-130 {
  height: 4px;
  left: 563px;
  object-fit: cover;
  position: absolute;
  top: 300px;
  width: 3px;
}

.login-page-first .path-131 {
  height: 4px;
  left: 568px;
  object-fit: cover;
  position: absolute;
  top: 305px;
  width: 3px;
}

.login-page-first .path-132 {
  height: 4px;
  left: 572px;
  object-fit: cover;
  position: absolute;
  top: 310px;
  width: 3px;
}

.login-page-first .path-133 {
  height: 4px;
  left: 576px;
  object-fit: cover;
  position: absolute;
  top: 316px;
  width: 3px;
}

.login-page-first .path-134 {
  height: 4px;
  left: 580px;
  object-fit: cover;
  position: absolute;
  top: 322px;
  width: 3px;
}

.login-page-first .path-135 {
  height: 4px;
  left: 583px;
  object-fit: cover;
  position: absolute;
  top: 327px;
  width: 3px;
}

.login-page-first .path-136 {
  height: 4px;
  left: 587px;
  object-fit: cover;
  position: absolute;
  top: 333px;
  width: 3px;
}

.login-page-first .path-137 {
  height: 4px;
  left: 590px;
  object-fit: cover;
  position: absolute;
  top: 339px;
  width: 3px;
}

.login-page-first .path-138 {
  height: 4px;
  left: 593px;
  object-fit: cover;
  position: absolute;
  top: 345px;
  width: 3px;
}

.login-page-first .path-139 {
  height: 4px;
  left: 596px;
  object-fit: cover;
  position: absolute;
  top: 351px;
  width: 2px;
}

.login-page-first .path-140 {
  height: 4px;
  left: 599px;
  object-fit: cover;
  position: absolute;
  top: 358px;
  width: 2px;
}

.login-page-first .path-141 {
  height: 4px;
  left: 602px;
  object-fit: cover;
  position: absolute;
  top: 364px;
  width: 2px;
}

.login-page-first .path-142 {
  height: 19px;
  left: 561px;
  object-fit: cover;
  position: absolute;
  top: 610px;
  width: 17px;
}

.login-page-first .path-143 {
  height: 16px;
  left: 536px;
  object-fit: cover;
  position: absolute;
  top: 633px;
  width: 19px;
}

.login-page-first .path-144 {
  height: 14px;
  left: 510px;
  object-fit: cover;
  position: absolute;
  top: 654px;
  width: 21px;
}

.login-page-first .path-145 {
  height: 11px;
  left: 481px;
  object-fit: cover;
  position: absolute;
  top: 671px;
  width: 22px;
}

.login-page-first .path-146 {
  height: 8px;
  left: 451px;
  object-fit: cover;
  position: absolute;
  top: 684px;
  width: 23px;
}

.login-page-first .path-147 {
  height: 6px;
  left: 419px;
  object-fit: cover;
  position: absolute;
  top: 693px;
  width: 24px;
}

.login-page-first .path-148 {
  height: 2px;
  left: 387px;
  object-fit: cover;
  position: absolute;
  top: 699px;
  width: 24px;
}

.login-page-first .path-149 {
  height: 3px;
  left: 355px;
  object-fit: cover;
  position: absolute;
  top: 699px;
  width: 24px;
}

.login-page-first .path-150 {
  height: 6px;
  left: 324px;
  object-fit: cover;
  position: absolute;
  top: 693px;
  width: 24px;
}

.login-page-first .path-151 {
  height: 9px;
  left: 293px;
  object-fit: cover;
  position: absolute;
  top: 683px;
  width: 23px;
}

.login-page-first .path-152 {
  height: 12px;
  left: 264px;
  object-fit: cover;
  position: absolute;
  top: 669px;
  width: 22px;
}

.login-page-first .path-153 {
  height: 14px;
  left: 237px;
  object-fit: cover;
  position: absolute;
  top: 652px;
  width: 21px;
}

.login-page-first .path-154 {
  height: 17px;
  left: 212px;
  object-fit: cover;
  position: absolute;
  top: 632px;
  width: 19px;
}

.login-page-first .path-155 {
  height: 19px;
  left: 190px;
  object-fit: cover;
  position: absolute;
  top: 608px;
  width: 17px;
}

.login-page-first .path-156 {
  height: 21px;
  left: 171px;
  object-fit: cover;
  position: absolute;
  top: 582px;
  width: 15px;
}

.login-page-first .path-157 {
  height: 22px;
  left: 156px;
  object-fit: cover;
  position: absolute;
  top: 554px;
  width: 12px;
}

.login-page-first .path-158 {
  height: 23px;
  left: 145px;
  object-fit: cover;
  position: absolute;
  top: 524px;
  width: 9px;
}

.login-page-first .path-159 {
  height: 24px;
  left: 138px;
  object-fit: cover;
  position: absolute;
  top: 492px;
  width: 6px;
}

.login-page-first .path-160 {
  height: 24px;
  left: 134px;
  object-fit: cover;
  position: absolute;
  top: 461px;
  width: 3px;
}

.login-page-first .path-161 {
  height: 24px;
  left: 134px;
  object-fit: cover;
  position: absolute;
  top: 428px;
  width: 2px;
}

.login-page-first .path-162 {
  height: 24px;
  left: 136px;
  object-fit: cover;
  position: absolute;
  top: 397px;
  width: 5px;
}

.login-page-first .path-163 {
  height: 24px;
  left: 142px;
  object-fit: cover;
  position: absolute;
  top: 366px;
  width: 8px;
}

.login-page-first .path-164 {
  height: 23px;
  left: 152px;
  object-fit: cover;
  position: absolute;
  top: 336px;
  width: 11px;
}

.login-page-first .path-165 {
  height: 21px;
  left: 165px;
  object-fit: cover;
  position: absolute;
  top: 308px;
  width: 14px;
}

.login-page-first .path-166 {
  height: 20px;
  left: 183px;
  object-fit: cover;
  position: absolute;
  top: 283px;
  width: 16px;
}

.login-page-first .path-167 {
  height: 17px;
  left: 203px;
  object-fit: cover;
  position: absolute;
  top: 261px;
  width: 18px;
}

.login-page-first .path-168 {
  height: 15px;
  left: 227px;
  object-fit: cover;
  position: absolute;
  top: 241px;
  width: 20px;
}

.login-page-first .path-169 {
  height: 13px;
  left: 253px;
  object-fit: cover;
  position: absolute;
  top: 225px;
  width: 22px;
}

.login-page-first .path-170 {
  height: 10px;
  left: 282px;
  object-fit: cover;
  position: absolute;
  top: 213px;
  width: 23px;
}

.login-page-first .path-171 {
  height: 7px;
  left: 312px;
  object-fit: cover;
  position: absolute;
  top: 205px;
  width: 24px;
}

.login-page-first .path-172 {
  height: 4px;
  left: 343px;
  object-fit: cover;
  position: absolute;
  top: 200px;
  width: 24px;
}

.login-page-first .path-173 {
  height: 1px;
  left: 375px;
  object-fit: cover;
  position: absolute;
  top: 200px;
  width: 24px;
}

.login-page-first .path-174 {
  height: 4px;
  left: 407px;
  object-fit: cover;
  position: absolute;
  top: 201px;
  width: 24px;
}

.login-page-first .path-175 {
  height: 7px;
  left: 439px;
  object-fit: cover;
  position: absolute;
  top: 206px;
  width: 24px;
}

.login-page-first .path-176 {
  height: 10px;
  left: 470px;
  object-fit: cover;
  position: absolute;
  top: 215px;
  width: 23px;
}

.login-page-first .path-177 {
  height: 13px;
  left: 499px;
  object-fit: cover;
  position: absolute;
  top: 228px;
  width: 22px;
}

.login-page-first .path-178 {
  height: 16px;
  left: 527px;
  object-fit: cover;
  position: absolute;
  top: 244px;
  width: 20px;
}

.login-page-first .path-179 {
  height: 18px;
  left: 552px;
  object-fit: cover;
  position: absolute;
  top: 264px;
  width: 18px;
}

.login-page-first .group-4 {
  background-image: url(../../../public/img/path-3228.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 446px;
  left: 165px;
  position: absolute;
  top: 222px;
  width: 424px;
}

.login-page-first .ellipse {
  background-color: #002842;
  border-radius: 4.73px;
  height: 9px;
  left: 181px;
  position: absolute;
  top: 592px;
  width: 9px;
}

.login-page-first .path-180 {
  height: 9px;
  left: 211px;
  object-fit: cover;
  position: absolute;
  top: 223px;
  width: 9px;
}

.login-page-first .ellipse-2 {
  background-color: #002842;
  border-radius: 4.73px;
  height: 9px;
  left: 161px;
  position: absolute;
  top: 66px;
  width: 9px;
}

.login-page-first .path-181 {
  height: 9px;
  left: 45px;
  object-fit: cover;
  position: absolute;
  top: 397px;
  width: 9px;
}

.login-page-first .ellipse-3 {
  background-color: #002842;
  border-radius: 4.73px;
  height: 9px;
  left: 367px;
  position: absolute;
  top: 35px;
  width: 9px;
}

.login-page-first .ellipse-4 {
  background-color: #002842;
  border-radius: 4.73px;
  height: 9px;
  left: 354px;
  position: absolute;
  top: 0;
  width: 9px;
}

.login-page-first .path-182 {
  height: 9px;
  left: 257px;
  object-fit: cover;
  position: absolute;
  top: 172px;
  width: 9px;
}

.login-page-first .path-183 {
  height: 9px;
  left: 605px;
  object-fit: cover;
  position: absolute;
  top: 88px;
  width: 9px;
}

.login-page-first .ellipse-5 {
  background-color: #002842;
  border-radius: 4.73px;
  height: 9px;
  left: 550px;
  position: absolute;
  top: 246px;
  width: 9px;
}

.login-page-first .ellipse-6 {
  background-color: #002842;
  border-radius: 4.73px;
  height: 9px;
  left: 424px;
  position: absolute;
  top: 281px;
  width: 9px;
}

.login-page-first .path-184 {
  height: 7px;
  left: 251px;
  object-fit: cover;
  position: absolute;
  top: 732px;
  width: 7px;
}

.login-page-first .path-185 {
  height: 7px;
  left: 379px;
  object-fit: cover;
  position: absolute;
  top: 800px;
  width: 7px;
}

.login-page-first .path-186 {
  height: 7px;
  left: 705px;
  object-fit: cover;
  position: absolute;
  top: 832px;
  width: 7px;
}

.login-page-first .form-field {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #95989a;
  border-radius: 4px;
  height: 50px;
  left: 316px;
  position: absolute;
  top: 327px;
  width: 307px;
}

.login-page-first .password {
  background-color: transparent;
  border: 0;
  color: #262626;
  font-family: "Montserrat", Helvetica;
  font-size: 15px;
  font-weight: 400;
  height: 21px;
  left: 334px;
  letter-spacing: 0;
  line-height: 15px;
  padding: 0;
  position: absolute;
  top: 342px;
  white-space: nowrap;
  width: 255px;
}

.login-page-first .path-187 {
  height: 16px;
  left: 594px;
  object-fit: cover;
  position: absolute;
  top: 342px;
  width: 13px;
}

.login-page-first .path-188 {
  height: 3px;
  left: 600px;
  object-fit: cover;
  position: absolute;
  top: 352px;
  width: 2px;
}

.login-page-first .div-wrapper {
  background-color: #002842;
  border-radius: 6px;
  height: 45px;
  left: 0;
  position: absolute;
  top: 395px;
  transition: all 0.2s ease;
  width: 623px;
}

.login-page-first .text-wrapper-2 {
  color: #ffffff;
  font-family: "Governor-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 278px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 14px;
  white-space: nowrap;
}

.login-page-first .p {
  color: #919191;
  font-family: "Montserrat", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 218px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 824px;
  white-space: nowrap;
}

.login-page-first .username {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #95989a;
  border-radius: 4px;
  color: #262626;
  font-family: "Montserrat", Helvetica;
  font-size: 15px;
  font-weight: 400;
  height: 21px;
  left: 20px;
  letter-spacing: 0;
  line-height: 15px;
  padding: 0;
  position: absolute;
  top: 14px;
  white-space: nowrap;
  width: 306px;
}

.login-page-first .text-wrapper-3 {
  color: #f5911e;
  font-family: "Montserrat", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 226px;
  letter-spacing: 0;
  line-height: 15px;
  position: absolute;
  top: 464px;
  transition: all 0.2s ease;
  white-space: nowrap;
}
