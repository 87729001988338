.dashboard-page {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full viewport height */
}

.tabs-container {
    display: flex;
    justify-content: space-around;
    flex: 2;
    font-family: "montserrat", sans-serif;
    font-weight: 400;
    font-style: normal;

}

.user-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    /*flex: 1;*/
    background-color: #002842;
    color: #FFB964;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
}

.tab {
    color: white;
    cursor: pointer;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: #002842;
    /*padding-top: 10px;*/
    /*padding-bottom: 10px;*/
    padding: 10px;
    font-size: 0.8em;
    font-weight: bold;
    height: 100%;

}

.selected-tab {
    position: relative;
    color: #FFB964
}

.selected-tab::before {
    content: '';
    position: absolute;
    top: calc(-50% - 3.5px);
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 100%;
    border-top: solid #FFB964 7px;
}

.selected-tab {
    /*padding: 10px;*/

}


.content-container {
    flex-grow: 1; /* Takes up the remaining space */
    padding: 5px; /* Add padding as needed */
    height: 100%;
    /* Additional styling for the content area */
}
